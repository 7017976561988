import styled from 'styled-components'
import colors from '../../../assets/style/colors'

const PaymentModalStyle = styled.div`
	width: 700px;
	.card {
		background-color: #fffefe01;
		border-radius: 5px;
		width: 400px;
		overflow: scroll;
	}
	.centerCard {
		display: flex;
		justify-content: space-between;
		/* padding: 0 50px; */
	}
	.btnY {
		padding: 10px 30px;
		border-radius: 5px;
		border: 3px solid green;
		color: green;
		transition: 0.2s;
		font-weight: 600;
		font-size: 17px;
	}
	.btnY:hover {
		background-color: green;
		color: white;
	}

	.btnN {
		padding: 10px 30px;
		border-radius: 5px;
		border: 3px solid red;
		color: red;
		transition: 0.2s;
		font-weight: 600;
		font-size: 17px;
	}
	.btnN:hover {
		background-color: red;
		color: white;
	}
	.radioBtnCard {
		display: flex;
		justify-content: space-between;
		padding-bottom: 20px;
	}
	.radioBtnCard2 {
		display: flex;
		justify-content: space-around;
		margin-bottom: 20px;
	}
	.ac {
		background-color: ${colors.mainColor};
		color: #ffffff;
		border: none;
		padding: 10px 10px;
		width: 100%;
		border-radius: 10px;
		align-items: center;
	}
	.inac {
		background-color: #e3e3e3;
		border: none;
		padding: 10px 10px;
		width: 100%;
		border-radius: 10px;
		align-items: center;
	}
	.btnTitle {
		margin: 0;
		padding: 0;
	}
	.halfCard {
		display: flex;
		.insideMiniCard {
			flex: 1;
			.input {
				${'' /* border: 2px solid ${colors.gray}; */}
				font-size: 15px;
				padding: 10px;
				border-radius: 5px;
				color: ${colors.mainColor};
				width: 80%;
				height: 10px;
			}
		}
	}
	@media (max-width: 640px) {
		width: 100%;
		.card {
			width: 100%;
			padding: 10px;
		}

		.centerCard,
		.radioBtnCard,
		.radioBtnCard2 {
			flex-direction: column; /* Stack elements vertically */
			align-items: center;
		}

		.btnY,
		.btnN {
			width: 100%; /* Make buttons full-width */
			font-size: 15px;
			padding: 8px 20px;
		}

		.halfCard {
			flex-direction: column; /* Stack cards vertically */
			.insideMiniCard {
				.input {
					width: 100%; /* Make input full-width */
				}
			}
		}
	}
`
export default PaymentModalStyle
