import React, { memo, useEffect, useState, useRef } from 'react'
import CheckByPhoneModalStyle from '../CheckByPhoneModal/CheckByPhoneModalStyle'
import Modal from 'react-modal'
import { ErrorModal, SuccessModal } from '../index'
import { get } from 'lodash'
import api from '../../../api'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		maxHeight: '100vh',
		width: '85%',
	},
	overlay: {
		background: '#00000099',
	},
}

const AddPoliceOutgoingModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	phones = '',
}) => {
	const { t } = useTranslation()

	const errorModalRef = useRef()
	const successRef = useRef()
	const inputRef = useRef(null)

	const [date, setDate] = useState(new Date().toISOString().split('T')[0])
	const errorRef = useRef()
	const [currency, setCurrency] = useState('USD')
	const [cashAccountsData, setCashAccountsData] = useState([])
	const [spendAccountsData, setSpendAccountsData] = useState([])
	const [formData, setFormData] = useState({
		CardCode: '',
		CashAccount: '',
		CashSum: 0,
		AccountCode: '',
		cashFlow: '7',
	})
	const [btnLoading, setBtnLoading] = useState(false)
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [remarks, setRemarks] = useState(null)
	const [docEntry, setDocEntry] = useState('')
	const [userFields, setUserFields] = useState([])

	const handleSpendAccountClick = (event) => {
		if (inputRef.current) {
			inputRef.current.value = event.target.innerText
		}
		setFormData((prev) => ({
			...prev,
			CardCode: event.target.value,
		}))
	}

	const cashAccounts = async () => {
		const data = await api(
			`ChartOfAccounts?$select=Code,Name&$filter=startswith(Code, '5') and ExternalCode eq '1' and (AcctCurrency eq '${currency}' or AcctCurrency eq '%23%23')`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			},
		).then((res) => {
			return JSON.parse(res.data).value
		})
		setCashAccountsData(data)
	}

	const userFieldsFn = () => {
		api
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'ORCT'",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}
	const typeSpend = async () => {
		const data = await api(
			`ChartOfAccounts?$select=Code,Name&$filter=startswith(Code, '94') and ExternalCode eq '5' and (AcctCurrency eq '${currency}' or AcctCurrency eq '%23%23')`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			},
		).then((res) => {
			return JSON.parse(res.data).value
		})
		setSpendAccountsData(data)
		// setFormData((prev) => ({
		// 	...prev,
		// 	CardCode: data[0]?.Code,
		// }))
	}

	const submitForm = async () => {
		setBtnLoading(true)

		try {
			await api
				.post('VendorPayments', {
					DocDate: date + 'T00:00:00Z',
					DocType: 'rAccount',
					CardCode: formData.CardCode,
					CashAccount: formData.CashAccount,
					Remarks: remarks ? remarks : null,
					DocCurrency: currency,
					CashSum: formData.CashSum,
					U_CashFlow: formData.cashFlow,
					U_Invoice: docEntry,
					PaymentAccounts: [
						{
							AccountCode: formData.CardCode,
							SumPaid: formData.CashSum,
						},
					],
				})
				.then((res) => {
					successRef.current?.open(t('sucessAdded'))
					resetForm()
				})
		} catch (err) {
			if (err) {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			}
		} finally {
			setBtnLoading(false)
		}
	}

	const handleDateChange = (event) => {
		setDate(event.target.value)
	}

	const handleCurrencyChange = (event) => {
		setCurrency(event.target.value)
		setSpendAccountsData([])
		setCashAccountsData([])
		if (inputRef.current) {
			inputRef.current.value = ''
		}
		setFormData((prev) => ({
			...prev,
			CashSum: null,
			CardCode: null,
		}))
	}

	const handleClickAccountCode = (event) => {
		setFormData((prev) => ({
			...prev,
			CashAccount: event.target.value,
		}))
	}

	const handleRemarks = (event) => {
		setRemarks(event.target.value)
	}

	const resetForm = () => {
		setDocEntry('')
		setRemarks('')
		setCurrency('USD')
		setDate(new Date().toISOString().split('T')[0])
		setFormData({
			CardCode: '',
			CashAccount: '',
			CashSum: 0,
			AccountCode: '',
			cashFlow: '7',
		})
	}

	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true)
				setDocEntry(t)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	useEffect(() => {
		cashAccounts()
		typeSpend()
		userFieldsFn()
	}, [currency])

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CheckByPhoneModalStyle>
				<div className="container">
					<p className="productTitle text-xl font-bold text-center py-8">
						{t('addPoliceExpense')}
					</p>

					<div>
						<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-6">
							<div className="flex flex-col">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('currency')}
								</p>
								<select
									className={
										'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
									}
									value={currency}
									onChange={handleCurrencyChange}
								>
									<option value="USD">USD</option>
									<option value="UZS">UZS</option>
								</select>
							</div>
							<div className="flex flex-col">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('expenseCash')}
								</p>
								<select
									value={formData.CardCode}
									className={
										'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
									}
									onChange={handleSpendAccountClick}
								>
									<option value=""></option>

									{spendAccountsData.map((item) => {
										;<option key={item.Code} value=""></option>
										return (
											<option key={item.Code} value={item.Code}>
												{item.Name} - {item.Code}
											</option>
										)
									})}
								</select>
							</div>
							<div className="flex flex-col">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('date')}
								</p>
								<input
									className={
										'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
									}
									type="date"
									defaultValue={date}
									onChange={handleDateChange}
								/>
							</div>
						</div>
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-3">
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('cashAcc')}
								</p>
								<select
									value={formData.CashAccount}
									className={
										'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
									}
									onChange={handleClickAccountCode}
								>
									<option value=""></option>

									{cashAccountsData.map((item) => {
										return (
											<option key={item.Code} value={item.Code}>
												{item.Name} - {item.Code}
											</option>
										)
									})}
								</select>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('price')}
								</p>
								<input
									className={
										'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full  flex-1'
									}
									type="number"
									onChange={(e) =>
										setFormData((prev) => ({
											...prev,
											CashSum: parseFloat(e.target.value),
										}))
									}
									value={formData.CashSum}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('comment')}
								</p>
								<textarea
									className={
										'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
									}
									onChange={handleRemarks}
								/>
							</div>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('dds')}
							</p>
							<select
								value={formData.cashFlow}
								className={
									'border-[1px] border-[#DFE2E9] p-1 rounded-md w-full sm:w-[33%]'
								}
								disabled={true}
								onChange={(e) => {
									setFormData((prev) => ({
										...prev,
										cashFlow: e.target.value, // Use e.target.value to get the selected option value
									}))
								}}
							>
								<option value=""></option>
								{userFields
									?.find((item) => item.Name === 'CashFlow')
									?.ValidValuesMD.map((value) => (
										<option key={value.Value} value={value.Value}>
											{value.Description}
										</option>
									))}
							</select>
						</div>
						<div className="flex justify-center py-4">
							<Button
								onClick={submitForm}
								loading={btnLoading}
								disabled={
									!formData.CashAccount ||
									!formData.CardCode ||
									!(+formData.CashSum > 0)
								}
								className="bg-blue-900 text-white h-10 hover:bg-blue-700"
							>
								{t('add2')}
							</Button>
						</div>
					</div>
				</div>

				<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
				<SuccessModal
					getRef={(r) => {
						successRef.current = r
					}}
					onConfirm={() => setIsOpenModal(false)}
				/>
			</CheckByPhoneModalStyle>
		</Modal>
	)
}

export default memo(AddPoliceOutgoingModal)
