import React, { useEffect } from 'react'
import qr from '../../assets/images/QRCode.png'
import formatPhoneNumber from '../../helpers/PhoneWithSpaces'
import logo from '../../assets/images/logo.png'
import './main.css'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

export default function Cheque() {
	const { t } = useTranslation()
	const { state } = useLocation()
	const { data } = state || {}

	const {
		InstId,
		DocDate,
		totalInUZS,
		CardName,
		phoneNum,
		Dscription,
		Time,
		IncomingDocNum,
		CardCode,
		InvoiceDocNum,
		debtTotal,
	} = data || {}
	useEffect(() => {
		window.print()
	}, [])
	return (
		<div>
			<div className=" pdf w-[219px] p-1  border border-gray-300 rounded-md shadow-md">
				<p className={'mb-1 text-center'}>***************************</p>

				<div className="textAlign-center  mb-1 topCard">
					<img
						src={logo}
						className="w-[120px]"
						alt={'check list in credo mobile'}
					/>
				</div>

				<p className="topTitle">{t('creditBlank')}</p>
				<div className="border-t border-black mb-1 card"></div>

				<div className="betweenCard2">
					<p className="title">{t('idNum')}</p>
					<p className="title2 ">{InstId}</p>
				</div>
				<div className="betweenCard2">
					<p className="title">{t('IncomingId')} :</p>
					<p className="title2 ">{IncomingDocNum}</p>
				</div>
				<div className="betweenCard2">
					<p className="title">{t('invoiceId')} :</p>
					<p className="title2 ">{InvoiceDocNum}</p>
				</div>
				<div className="betweenCard2">
					<p className="title">{t('idClient')}:</p>
					<p className="title2 ">{CardCode}</p>
				</div>

				<div className="betweenCard2">
					<p className="title">{t('paymnetDate')}:</p>
					<p className="title2">
						{' '}
						{`${moment(DocDate).format('DD-MM-YYYY')} ${Time ? moment(`${String(Time).padStart(4, '0').slice(0, 2)}:${String(Time).padStart(4, '0').slice(2)}`, 'HH:mm').format('HH:mm') : ''}`}
					</p>
				</div>

				<div className="betweenCard2">
					<p className="title">{t('paymentAmount')}:</p>
					<p className="title2">
						{new Intl.NumberFormat('fr-FR', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})
							.format(totalInUZS)
							.replace(',', '.')}
					</p>
				</div>
				<div className="betweenCard2">
					<p className="title">{t('debtTotal2')}:</p>
					<p className="title2">
						{new Intl.NumberFormat('fr-FR', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})
							.format(debtTotal)
							.replace(',', '.')}
					</p>
				</div>
				<div className="betweenCard2">
					<p className="title">{t('payersName')}:</p>
					<p className="title2">{CardName}</p>
				</div>

				<div className="betweenCard2">
					<p className="title">{t('payersPhone')}:</p>
					<p className="title2">{formatPhoneNumber(phoneNum)}</p>
				</div>
				<div className="betweenCard2">
					<p className="title">{t('productName')}</p>
					{/* get(data, 'Dscription', '-') */}
					<p className="title2">{Dscription}</p>
				</div>

				<div className="betweenCard2">
					<p className="title">Подпис/Imzo:</p>
				</div>

				<p className="title2">Telegram kanalga ulanish uchun QR kod:</p>
				<div className="flex items-center justify-center mb-1">
					<img src={qr} alt="" className="w-[150px] " />
				</div>
				<div className="border-t border-black mb-1 text-decoration-dashed card"></div>
				<div className="betweenCard2">
					<p className="title">Aloqa uchun telefon</p>
					<p className="title2">71-200-30-07</p>
				</div>
				<p className={'mt-2 text-center'}>***************************</p>
			</div>
		</div>
	)
}
