import React, { useEffect, useRef, useState } from 'react'
import CreateUserStyle from './CreateUserStyle'
import Modal from 'react-modal'
import { get } from 'lodash'
import api from '../../../api'
import { useSelector } from 'react-redux'
import { ErrorModal } from '../index'
import { useTranslation } from 'react-i18next'
import { Button as AntButton } from 'antd'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		overflowX: 'auto',
	},
	overlay: {
		background: '#00000099',
	},
}

const CreateSuppliersModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
}) => {
	const { getMe } = useSelector((state) => state.main)
	const errorModalRef = useRef()
	const { t } = useTranslation()

	const [magazineNum, setMagazineNum] = useState('')

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [phone, setPhone] = useState('')
	const [cardName, setCardName] = useState('')

	const [loading, setLoading] = useState(false)

	const resetForm = () => {
		setMagazineNum('')
		setPhone('')
		setCardName('')
	}

	useEffect(() => {
		const ref = {
			open: () => {
				resetForm()
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const createUserF = () => {
		setLoading(true)
		api
			.post(`BusinessPartners`, {
				Series: 75, // CardCode
				CardName: cardName, // UserName, userSurname, userFathersName,
				CardType: 'cSupplier', // By default
				U_Telephone: `${phone}`,
				Currency: '##', // By default
				Valid: 'tYES',
				U_Created: get(getMe, 'EmployeeID', ''),
				U_Shop_Number: magazineNum,
			})
			.then(() => {
				onConfirm()
				setIsOpenModal(false)
				// sucModalRef.current?.open('Клиент успешно создан!')
			})
			.catch((err) => {
				errorModalRef.current?.open(JSON.parse(err.response.data).error.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<p className="title">{t('createSupplier')}</p>
					<div className="flex flex-col gap-5">
						<div className="flexCard">
							<input
								type="text"
								placeholder={t('supplierName')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
								}
								value={cardName}
								onChange={(v) => setCardName(v.target.value)}
							/>
						</div>

						<div className="flexCard">
							<textarea
								type="number"
								placeholder={t('phone')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md halfInput'
								}
								value={phone}
								onChange={(v) => setPhone(v.target.value)}
							/>

							<input
								type="text"
								placeholder={t('storeNum')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput h-10'
								}
								value={magazineNum}
								onChange={(v) => setMagazineNum(v.target.value)}
							/>
						</div>
					</div>

					<div className="centerCard w-full">
						<AntButton
							className="bg-red-500 text-white py-2 px-4 rounded-md w-full sm:w-[100px]"
							onClick={() => {
								onClose()
								setIsOpenModal(false)
							}}
						>
							{t('no')}
						</AntButton>
						<AntButton
							isLoading={loading}
							className="bg-[#0A4D68] text-white py-2 px-4 rounded-md w-full sm:w-[100px]"
							disabled={!cardName}
							onClick={createUserF}
						>
							{t('yes')}
						</AntButton>
					</div>
				</div>
			</CreateUserStyle>
			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
			{/* <ConfirmModal
				show={showModal}
				data={confirmationMessage}
				onConfirm={createUserF}
				onCancel={() => {
					setShowModal(false)
				}}
			/> */}
			{/* <SuccessModal
				getRef={(r) => {
					sucModalRef.current = r
				}} */}
		</Modal>
	)
}

export default CreateSuppliersModal
