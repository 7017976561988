export const API_ROOT_TEST = 'https://istyle-backend-test.bis-pro.com/b1s/v2/'
// export const API_ROOT_TEST = 'https://credo-back-prod.bis-pro.com/b1s/v2/'

export const CUSTOM_API_ROOT_TEST =
	'https://istyle-backend-test.bis-pro.com/api/'
// export const CUSTOM_API_ROOT_TEST = 'https://credo-back-prod.bis-pro.com/api'
export const API_ROOT_PROD = 'https://credo-back-prod.bis-pro.com/b1s/v2/'
export const CUSTOM_API_ROOT_PROD = 'https://credo-back-prod.bis-pro.com/api/'

export const API_PHOTO_UPLOAD_PROD =
	'https://credo-file-upload.dotnet-csharp.uz/api/'
export const API_PHOTO_UPLOAD_TEST =
	'https://credo-file-upload.dotnet-csharp.uz/api/'
