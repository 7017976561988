import React, { useEffect, useRef, useState } from 'react'
import CreateUserStyle from '../CreateUser/CreateUserStyle'
import Modal from 'react-modal'
import Button from '../../Button'
import { get } from 'lodash'
import { customFuncsApi } from '../../../api'
import customMainApi from '../../../api'
import { ErrorModal, SuccessModal } from '../index'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import api from '../../../api'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		overflowX: 'auto',
		...(window.innerWidth <= 640 ? { height: '90%' } : {}),
	},
	overlay: {
		background: '#00000099',
	},
}

const AddSpisaniyaModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
	onUpdated = () => {},
}) => {
	const { t } = useTranslation()

	const errorModalRef = useRef()
	const successModalRef = useRef()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [item, setItem] = useState('')
	const [loading, setLoading] = useState(false)
	const [userFields, setUserFields] = useState([])
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
	const [debt, setDebt] = useState('')
	const [docEntry, setDocEntry] = useState('')
	const [comment, setComment] = useState('')
	const [status, setStatus] = useState('')
	const [userFields2, setUserFields2] = useState([])
	const [cashAcc, setCashAcc] = useState('')
	const [cashAccountsData, setCashAccountsData] = useState([])

	useEffect(() => {
		const ref = {
			open: (data) => {
				setIsOpenModal(true)
				setCardCode(
					get(data, 'CardCode', null) !== null ? get(data, 'CardCode', '') : '',
				)
				setDocEntry(
					get(data, 'DocEntry', null) !== null ? get(data, 'DocEntry', '') : '',
				)
				setItem(
					get(data, 'Dscription', null) !== null
						? get(data, 'Dscription', '')
						: '',
				)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const submit = () => {
		setLoading(true)

		customFuncsApi
			.post(`closeInvoiceDebt`, {
				DocDate: date,
				invoiceDocEntry: docEntry,
				discount: debt,
				itemPrice: get(userFields, 'DocTotal', ''),
				CardCode: cardCode,
				U_CashFlow: '4',
				Remarks: comment,
				'Security.U_Status': status,
				account: cashAcc,
			})
			.then(() => {
				setTimeout(() => {
					onConfirm()
					onUpdated()
					setIsOpenModal(false)
				}, 2000)
				successModalRef.current?.open('Пользователь успешно создан')
			})
			.catch((err) => {
				errorModalRef.current?.open(JSON.parse(err.response.data).error.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const getInvoices = () => {
		customMainApi
			.get(`Invoices(${docEntry})`, {
				params: {
					$select:
						'DocDate,DocTotal,CardName,CardCode,SalesPersonCode,PaidToDate,DocumentLines,U_Security',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data))
				setDebt(
					get(JSON.parse(res.data), 'DocTotal', 0) -
						get(JSON.parse(res.data), 'PaidToDate', 0),
				)
				getIclouds(get(JSON.parse(res.data), 'U_Security', 0))
			})
	}

	const getIclouds = (code) => {
		if (code) {
			customMainApi
				.get(`Security('${code}')`, {
					params: {
						$select:
							'Code,Name,U_Whichtelephone,U_Password,U_Status,U_TypeAccount',
					},
					headers: {
						Prefer: 'odata.maxpagesize=100',
					},
				})
				.then((res) => {
					setStatus(JSON.parse(res.data)?.value?.U_Status)
				})
		}
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq '@Security'",
				},
			})
			.then((res) => {
				setUserFields2(JSON.parse(res.data).value)
			})
	}

	useEffect(() => {
		if (docEntry) {
			getInvoices()
			const today = moment().format('YYYY-MM-DD')
			setDate(today)
		}
		userFieldsFn()
	}, [docEntry])

	useEffect(() => {
		cashAccounts()
	}, [])

	const cashAccounts = async () => {
		const data = await api(
			`ChartOfAccounts?$select=Code,Name&$filter=startswith(Code, '94') and ExternalCode eq '4'`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			},
		).then((res) => {
			return JSON.parse(res.data).value
		})
		setCashAccountsData(data)
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card overflow-y-auto overflow-x-auto w-full">
					<p className="title">{t('discounts')}</p>
					<div className="flex flex-col gap-10 mb-20">
						<div className="flex justify-between items-center gap-5 flex-col sm:flex-row">
							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('date')}</p>
								<input
									type="date"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
									}
									value={date}
									onChange={(v) => setDate(v.target.value)}
								/>
							</div>

							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('customer')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
									}
									value={get(userFields, 'CardName', '')}
									disabled={true}
								/>
							</div>

							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('itemPrice')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-auto'
									}
									value={get(userFields, 'DocTotal', '')}
									disabled={true}
								/>
							</div>
						</div>

						<div className="flex items-center justify-between flex-col sm:flex-row gap-5">
							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('model')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
									}
									value={item}
									disabled={true}
								/>
							</div>

							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('discoutPrice')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
									}
									value={debt}
									onChange={(e) => {
										setDebt(e.target.value)
									}}
									// disabled={true}
								/>
							</div>
						</div>

						<div className="flex items-center justify-between flex-col sm:flex-row gap-5">
							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('comment')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
									}
									value={comment}
									onChange={(v) => setComment(v.target.value)}
								/>
							</div>
							<div className="flex flex-col items-start gap-3 w-full">
								<p>{t('Securitystatus')}</p>
								<select
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
									}
									value={status}
									onChange={(v) => setStatus(v.target.value)}
								>
									{' '}
									<option value={''}></option>
									{userFields2
										?.find((item) => item.Name === 'Status')
										?.ValidValuesMD.map((value) => (
											<option value={value.Value}>{value.Description}</option>
										))}
								</select>
							</div>
						</div>

						<div className="flex flex-col items-start gap-3 w-full">
							<p>{t('cashAcc')}</p>
							<select
								value={cashAcc}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full sm:w-1/2 flex-1'
								}
								onChange={(e) => {
									setCashAcc(e.target.value)
								}}
							>
								<option value=""></option>

								{cashAccountsData.map((item) => {
									return (
										<option key={item.Code} value={item.Code}>
											{item.Name} - {item.Code}
										</option>
									)
								})}
							</select>
						</div>
					</div>

					<div className="centerCard">
						<button
							className="bg-red-500 text-white py-2 px-4 rounded-md btn"
							onClick={() => {
								onClose()
								setIsOpenModal(false)
							}}
						>
							{t('no')}
						</button>
						<Button isLoading={loading} className={'btn'} onClick={submit}>
							{t('add2')}
						</Button>
					</div>
				</div>
			</CreateUserStyle>
			<SuccessModal
				getRef={(r) => {
					successModalRef.current = r
				}}
			/>
			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
		</Modal>
	)
}

export default AddSpisaniyaModal
