import React, { memo, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { get } from 'lodash'
import api, { customPhotoUploadApi } from '../../../api'
import { ErrorModal } from '../../../components/Modal'
import CreateUserStyle from '../../../components/Modal/ScoreUserCreate/CreateUserStyle'
import { useSelector } from 'react-redux'
import customMainApi from '../../../api'
import DistrictData from '../../../components/Modal/ScoreUserCreate/Regions/districts.json'
import VillageData from '../../../components/Modal/ScoreUserCreate/Regions/villages.json'
import formatPhoneNumber from '../../../helpers/PhoneWithSpaces'
import { message, Input, Button as AntButton } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		height: '90%',

		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		border: 'none',
		borderRadius: 15,
		overflowX: 'auto',
	},
}

const UpdateUserModal = (props) => {
	const { getRef = () => {}, onUpdated = () => {}, onClose = () => {} } = props
	const { getMe } = useSelector((state) => state.main)
	const { t } = useTranslation()

	const errorRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [score, setScore] = useState('')
	const [katm, setKatm] = useState('')
	const [mib, setMib] = useState('')
	const [nasiya, setNasiya] = useState('')
	const [passport, setPassport] = useState('')
	const [phone, setPhone] = useState('')
	const [workplace, setWorkplace] = useState('')
	const [male, setMale] = useState('')
	const [userName, setUserName] = useState('')
	const [userSureName, setUserSureName] = useState('')
	const [userFathersName, setUserFathersName] = useState('')
	const [disData, setDisData] = useState([])
	const [villData, setVillData] = useState([])
	const [region, setRegion] = useState('')
	const [addresses, setAddress] = useState([])

	const [districtData, setDistrictData] = useState([])
	const [regionsData, setRegionsData] = useState([])
	const [citiesData, setCitiesData] = useState([])

	const [district, setDistrict] = useState('')
	const [village, setVillage] = useState('')
	const [path, setPath] = useState([])
	const [userFields, setUserFields] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [salary, setSalary] = useState('')

	const [comment, setComment] = useState('')
	const [comment2, setComment2] = useState('')
	const [address2, setAddress2] = useState('')

	const [language, setLanguage] = useState('')
	const [bornAdress, setBornAdress] = useState('')
	const [ageData, setAgeData] = useState('')
	// const [path, setPath] = useState([])
	useEffect(() => {
		const ref = {
			open: (data) => {
				setIsOpenModal(true)
				setCardCode(
					get(data, 'CardCode', null) !== null ? get(data, 'CardCode', '') : '',
				)

				api(
					`BusinessPartners('${get(data, 'CardCode', '')}')?$select=CardName,U_Comment,U_Salary,U_address,U_PhotoId,U_age_data,U_Created,FreeText,U_workplace,U_Telephone,U_Respawn,U_Language,U_Gender,U_PS,U_KATM,U_Score,U_MIB,U_Nasiya,BPAddresses`,
				).then((res) => {
					const data = JSON.parse(res.data)
					setUserName(
						get(data, 'CardName', null) !== null
							? get(data, 'CardName', '').split(' ')[1]
							: '',
					)
					setUserFathersName(
						get(data, 'CardName', null) !== null
							? get(data, 'CardName', '').split(' ')[2] +
									' ' +
									(get(data, 'CardName', '').split(' ')[3] || '')
							: '',
					)
					setUserSureName(
						get(data, 'CardName', null) !== null
							? get(data, 'CardName', '').split(' ')[0]
							: '',
					)
					const bpAddresses = get(data, 'BPAddresses', [])
					setAddress(
						Array.isArray(bpAddresses) && bpAddresses.length > 0
							? bpAddresses
							: [
									{
										AddressName: 1,
										RowNum: 0,
										BPCode: cardCode,
										U_CityCode: '',
										U_DistrictCode: '',
										U_RegionCode: '',
										StreetNo: '',
										BuildingFloorRoom: '',
										U_Apartment: '',
									},
								],
						// :[]
					)

					setWorkplace(get(data, 'U_workplace', ''))
					setPhone(formatPhoneNumber(get(data, 'U_Telephone', '')))
					setMale(get(data, 'U_Gender', ''))
					setPassport(get(data, 'U_PS', ''))
					setScore(get(data, 'U_Score', ''))
					setKatm(get(data, 'U_KATM', ''))
					setMib(get(data, 'U_MIB', ''))
					setNasiya(get(data, 'U_Nasiya', ''))
					setLanguage(get(data, 'U_Language', ''))
					setComment(get(data, 'FreeText', '-'))
					setComment2(get(data, 'U_Comment', '-'))
					setAddress2(get(data, 'U_address', ''))
					setBornAdress(get(data, 'U_Respawn', ''))

					setSalary(get(data, 'U_Salary, '))
					const ageData = get(data, 'U_age_data', '')
					setAgeData(ageData ? moment(ageData).format('YYYY-MM-DD') : '')
					const photoId = get(data, 'U_PhotoId', '')

					const photoPaths = photoId
						? photoId.includes(',')
							? photoId.split(',')
							: [photoId]
						: []

					const initialPath = photoPaths.map((path) => ({
						file: 'not empty',
						has: true,
						path,
						imgLoading: false,
					}))

					const finalPath =
						initialPath.length > 0
							? initialPath
							: [{ file: null, path: '', imgLoading: false }]

					setPath(finalPath)
				})

				// setData(data)

				// getBusinessPartners(get(data, 'CardCode', null) !== null ? get(data, 'CardCode', '') : '')
			},
			close: () => {
				setIsOpenModal(false)
				// resetStates()
			},
		}
		getRef(ref)
	}, [])

	const handleFileChange = (event, index) => {
		const updatedPath = [...path]
		updatedPath[index].file = event.target.files[0]
		setPath(updatedPath)
	}

	const uploadImg = async (index) => {
		const image = path[index]

		if (!image.file) {
			message.error('Фотография не выбрано')
			return
		}

		const formData = new FormData()
		formData.append('file', image.file)

		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 16; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		const title = 'YourTitle' // Replace with actual title

		formData.append('Title', title)
		formData.append('Key', randomKey)

		try {
			const updatedPath = [...path]
			updatedPath[index].imgLoading = true
			setPath(updatedPath)

			const res = await customPhotoUploadApi.post('assets/upload', formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})

			const { key } = res.data
			updatedPath[index].path = key
			setPath(updatedPath)

			message.success('Успешно загружено!')
		} catch (err) {
			message.error('Ошибка загрузки')
		} finally {
			const updatedPath = [...path]
			updatedPath[index].imgLoading = false
			setPath(updatedPath)
		}
	}

	// View the uploaded image
	const viewImage = async (index) => {
		const image = path[index]

		try {
			const res = await customPhotoUploadApi.get(
				`assets/download/${image.path}`,
				{
					responseType: 'blob',
				},
			)

			const url = window.URL.createObjectURL(
				new Blob([res.data], { type: 'image/png' }),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error('Ошибка загрузки файла')
		}
	}

	// Add a new empty row for uploading images
	const addNewRow = () => {
		setPath([...path, { file: null, path: '', imgLoading: false }])
	}

	const deleteImage = async (index) => {
		const image = path[index]
		let url = `BusinessPartners('${cardCode}')`

		try {
			const res = await customPhotoUploadApi.delete(`assets/${image.path}`)
			const photoId = path
				.map((images) => images.path)
				.filter((p) => p !== image.path) // Correct filter syntax
				.join(',')
			await api.patch(url, {
				U_PhotoId: photoId,
			})
			deleteRow(index) // Remove the image from the state after deletion
			message.success('Успешно удалено!')
		} catch (error) {
			message.error('Ошибка удаления файла')
		}
	}

	// Delete a specific row
	const deleteRow = (index) => {
		setPath(path.filter((_, i) => i !== index))
	}

	// const resetStates = () => {
	// 	setCardCode('')
	// 	setScore('')
	// 	setKatm('')
	// 	setMib('')
	// 	setNasiya('')
	// 	setPassport('')
	// 	setPhone('')
	// 	setWorkplace('')
	// 	setMale('')
	// 	setUserName('')
	// 	setUserSureName('')
	// 	setUserFathersName('')
	// 	setDisData([])
	// 	setVillData([])
	// 	setRegion('')
	// 	setAddress([])
	// 	setDistrict('')
	// 	setVillage('')
	// 	setPath('')
	// 	setPINFL('')
	// 	setUserFields([])
	// 	setIsLoading(false)
	// 	setComment('')
	// 	setLanguage('')
	// 	setBornAdress('')
	// }

	// const getBusinessPartners = async (cardCode) => {
	// 	const data = await api(`BusinessPartners('${cardCode}')`).then((res) => {
	// 		return JSON.parse(res.data).value
	// 	})

	// 	// const selectElementData = data.map((item) => {
	// 	// 	return {
	// 	// 		value: item.CardCode,
	// 	// 		label: item.CardName,
	// 	// 	}
	// 	// })
	// 	setUserName(
	// 		get(data, 'CardName', null) !== null
	// 			? get(data, 'CardName', '').split(' ')[1]
	// 			: '',
	// 	)
	// 	setUserFathersName(
	// 		get(data, 'CardName', null) !== null
	// 			? get(data, 'CardName', '').split(' ')[2] +
	// 					' ' +
	// 					(get(data, 'CardName', '').split(' ')[3] || '')
	// 			: '',
	// 	)
	// 	setUserSureName(
	// 		get(data, 'CardName', null) !== null
	// 			? get(data, 'CardName', '').split(' ')[0]
	// 			: '',
	// 	)
	// 	setWorkplace(
	// 		get(data, 'U_workplace', null) !== null
	// 			? get(data, 'U_workplace', '')
	// 			: '',
	// 	)
	// 	setPhone(
	// 		get(data, 'U_Telephone', null) !== null
	// 			? get(data, 'U_Telephone', '')
	// 			: '',
	// 	)
	// 	setMale(
	// 		get(data, 'U_Gender', null) !== null ? get(data, 'U_Gender', '') : '',
	// 	)
	// 	setPassport(
	// 		get(data, 'U_PS', null) !== null ? get(data, 'U_PS', '') : '',
	// 	)
	// 	setScore(
	// 		get(data, 'U_Score', null) !== null ? get(data, 'U_Score', '') : '',
	// 	)
	// 	setKatm(
	// 		get(data, 'U_KATM', null) !== null ? get(data, 'U_KATM', '') : '',
	// 	)
	// 	setMib(get(data, 'U_MIB', null) !== null ? get(data, 'U_MIB', '') : '')
	// 	setNasiya(
	// 		get(data, 'U_Nasiya', null) !== null ? get(data, 'U_Nasiya', '') : '',
	// 	)

	// 	setData(data)
	// }
	useEffect(() => {
		fetchRegions()
	}, [])

	const fetchDistrictsWithoutId = () => {
		api
			.get(`U_ADDRESS_DISCRTICT`, {
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			})
			.then((res) => {
				const items = JSON.parse(res.data).value
				setDistrictData(items)
			})
	}

	const fetchDistricts = (cityCode) => {
		if (cityCode.trim()) {
			api
				.get(`U_ADDRESS_DISCRTICT?$filter=U_CityCode eq '${cityCode}'`)
				.then((res) => {
					const items = JSON.parse(res.data).value
					setDistrictData(items)
				})
				.catch((error) => {
					console.error(
						'Error fetching districts in Scoring Clients Update:',
						error,
					)
				})
		}
	}

	const fetchCities = (rgId) => {
		// ?$filter=U_RegionCode eq rgId
		if (rgId.trim()) {
			api
				.get(`U_ADDRESS_CITY?$filter=U_RegionCode eq '${rgId}'`)
				.then((res) => {
					const items = JSON.parse(res.data).value
					setCitiesData(items)
				})
		}
	}
	const fetchCitiesWithoutId = () => {
		// ?$filter=U_RegionCode eq rgId

		api
			.get(`U_ADDRESS_CITY`, {
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			})
			.then((res) => {
				const items = JSON.parse(res.data).value
				setCitiesData(items)
			})
	}

	const fetchRegions = () => {
		api.get('U_ADDRESS_REGION').then((res) => {
			const items = JSON.parse(res.data).value
			setRegionsData(items)
		})
	}

	const handleAddressChange = (index, field, value) => {
		const newAddresses = addresses.map((address, i) => {
			if (i === index) {
				return { ...address, [field]: value }
			}
			return address
		})
		setAddress(newAddresses)
	}

	const submit = () => {
		setIsLoading(true)
		let url = `BusinessPartners('${cardCode}')`
		const trimmedSureName = userSureName.trim()
		const trimmedName = userName.trim()
		const trimmedFathersName = userFathersName.trim()
		const photoId = path
			.map((image) => image.path)
			.filter(Boolean)
			.join(',')
		api
			.patch(url, {
				U_workplace: workplace,
				CardName: `${trimmedSureName} ${trimmedName} ${trimmedFathersName}`, //UserName, userSurname, userFathersName,
				U_Telephone: `${phone}`,
				Currency: '##', // Bydefault
				U_Gender: male, // Erkak Ayol
				U_PS: passport, //Pasport
				U_Score: String(score).includes(' ') ? Number(score.split(' ')[0]) : '',
				U_KATM: katm, //Tavsirlab bergan yangi narsam
				U_MIB: mib, //Tavsirlab bergan yangi narsam
				U_Nasiya: nasiya, //Tavsirlab bergan yangi narsam
				Valid: 'tYES',
				U_Salary: salary,
				FreeText: comment,
				U_Comment: comment2,
				U_Respawn: bornAdress,
				U_Language: language,
				U_PhotoId: photoId,
				BPAddresses: addresses.map((address, idx) => ({
					AddressName: idx + 1, // Auto-generate something here if needed
					RowNum: address.RowNum,
					BPCode: address.BPCode,
					U_CityCode:
						address.U_CityCode.length > 4 && address.U_CityCode.includes(' - ')
							? Number(address.U_CityCode.split(' - ')[1])
							: address.U_CityCode, // Village
					U_DistrictCode:
						address.U_DistrictCode.length > 4 &&
						address.U_DistrictCode.includes(' - ')
							? Number(address.U_DistrictCode.split(' - ')[1])
							: address.U_DistrictCode, // District
					U_RegionCode:
						address.U_RegionCode.length > 4 &&
						address.U_RegionCode.includes(' - ')
							? Number(address.U_RegionCode.split(' - ')[1])
							: address.U_RegionCode, // Region
					BuildingFloorRoom: address.BuildingFloorRoom,
					StreetNo: address.StreetNo,
					U_Apartment: address.U_Apartment,
				})),
			})
			.then(() => {
				onUpdated()
				setIsOpenModal(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => setIsLoading(false))
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OCRD'",
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	useEffect(() => {
		userFieldsFn()
		fetchCitiesWithoutId()
		fetchDistrictsWithoutId()
	}, [])

	const getRegion = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = DistrictData.filter((v) => {
			return a === Number(get(v, 'region_id', 0))
		})
		setDisData(changed_data)
		setRegion(text)
		setDistrict('')
		setVillage('')
	}

	const getDistrict = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = VillageData.filter((v) => {
			return a === Number(get(v, 'district_id', 0))
		})
		setVillData(changed_data)
		setDistrict(text)
		setVillage('')
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<p className="title">{t('editUser')}</p>
					<div className="flex flex-col gap-4">
						<div className="flex flex-col sm:flex-row items-center gap-2">
							<div className="flex flex-col gap-2 items-start w-full">
								<p>{t('lastName')}</p>
								<input
									type="text"
									placeholder={t('lastName')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									value={userSureName}
									onChange={(v) => setUserSureName(v.target.value)}
								/>
							</div>
							<div className="flex flex-col gap-2 items-start w-full">
								<p>{t('name')}</p>
								<input
									type="text"
									placeholder={t('name')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={userName}
									onChange={(v) => setUserName(v.target.value)}
								/>
							</div>
							<div className="flex flex-col gap-2 items-start w-full">
								<p>{t('fatherName')}</p>
								<input
									type="text"
									placeholder={t('fatherName')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									value={userFathersName}
									onChange={(v) => setUserFathersName(v.target.value)}
								/>
							</div>
						</div>
						{addresses.map((address, index) => {
							const region = regionsData?.find(
								(value) => address.U_RegionCode === Number(value.Code),
							)
							const displayRegion = region ? `${region.Name}` : ''

							const city = citiesData?.find(
								(value) => address.U_CityCode === Number(value.Code),
							)
							const displayCity = city ? `${city.Name}` : ''

							const district = districtData?.find(
								(value) => address.U_DistrictCode === Number(value.Code),
							)
							const displayDistrict = district ? `${district.Name}` : ''

							return (
								<div className="flex flex-col gap-5 mt-4" key={index}>
									{index === 0 ? (
										<b>{t('addressReg')}</b>
									) : (
										<b>{t('addressLive')}</b>
									)}
									<hr className="w-full border-1 font-extrabold border-black" />

									<div className="flex items-center flex-col sm:flex-row gap-2">
										<div className="flex flex-col gap-2 items-start w-full">
											<p>{t('Region')}</p>
											<input
												type="text"
												placeholder={t('Region')}
												list="region"
												defaultValue={displayRegion}
												onChange={(v) => {
													getRegion(v.target.value)
													fetchCities(
														v.target.value.split(' - ')[1]
															? v.target.value.split(' - ')[1]
															: ' ',
													)
													handleAddressChange(
														index,
														'U_RegionCode',
														v.target.value,
													)
												}}
												className={
													'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
												}
											/>
											<datalist id="region">
												{regionsData.map((v, i) => (
													<option key={i} value={`${v.Name} - ${v.Code}`} />
												))}
											</datalist>
										</div>
										<div className="flex flex-col items-start gap-2 w-full">
											<p>{t('cityDistrict')}</p>
											<input
												type="text"
												placeholder={t('cityDistrict')}
												list="village"
												defaultValue={displayCity}
												onChange={(v) => {
													setVillage(v.target.value)
													fetchDistricts(
														v.target.value.split(' - ')[1]
															? v.target.value.split(' - ')[1]
															: ' ',
													)
													handleAddressChange(
														index,
														'U_CityCode',
														v.target.value,
													)
												}}
												className={
													'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
												}
											/>
											<datalist id="village">
												{citiesData.map((v, i) => (
													<option key={i} value={`${v.Name} - ${v.Code}`} />
												))}
											</datalist>
										</div>
										<div className="flex flex-col gap-2 items-start w-full">
											<p>{t('district')}</p>
											<input
												type="text"
												placeholder={t('district')}
												list="district"
												defaultValue={displayDistrict}
												onChange={(v) => {
													getDistrict(v.target.value)
													handleAddressChange(
														index,
														'U_DistrictCode',
														v.target.value,
													)
												}}
												className={
													'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
												}
											/>
											<datalist id="district">
												{districtData.map((v, i) => (
													<option key={i} value={`${v.Name} - ${v.Code}`} />
												))}
											</datalist>
										</div>
									</div>
									<div className="flex flex-col sm:flex-row items-center gap-2">
										<div className="flex flex-col gap-2 items-start w-full">
											<p>{t('streetName')}</p>
											<input
												type="text"
												placeholder={t('streetName')}
												value={address.StreetNo}
												onChange={(v) =>
													handleAddressChange(index, 'StreetNo', v.target.value)
												}
												className={
													'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
												}
											/>
										</div>
										<div className="flex flex-col items-start gap-2 w-full">
											<p>{t('buildingRoom')}</p>
											<input
												type="text"
												placeholder={t('buildingRoom')}
												value={address.BuildingFloorRoom}
												onChange={(v) =>
													handleAddressChange(
														index,
														'BuildingFloorRoom',
														v.target.value,
													)
												}
												className={
													'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
												}
											/>
										</div>
										<div className="flex flex-col items-start gap-2 w-full">
											<p>{t('apartmentNum')}</p>
											<input
												type="text"
												placeholder={t('apartmentNum')}
												value={address.U_Apartment}
												onChange={(v) =>
													handleAddressChange(
														index,
														'U_Apartment',
														v.target.value,
													)
												}
												className={
													'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
												}
											/>
										</div>
									</div>
								</div>
							)
						})}
						<div className="flex flex-col sm:flex-row items-center gap-2">
							<div className="flex flex-col gap-2 items-start w-full">
								<p>{t('passport/pinfl')}</p>
								<input
									type="text"
									placeholder={t('passport/pinfl')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full h-10'
									}
									value={passport}
									onChange={(v) => setPassport(v.target.value)}
								/>
							</div>
							<div className="flex flex-col items-start gap-2 w-full">
								<p>{t('phone')}</p>
								<textarea
									type="text"
									placeholder={t('phone')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									value={phone}
									onChange={(v) => setPhone(v.target.value)}
								/>
							</div>
						</div>{' '}
						<div className="flex flex-col sm:flex-row items-center gap-2">
							<div className="flex flex-col gap-2 items-start w-full">
								<p>{t('bornAddress')}</p>
								<input
									type="text"
									placeholder={t('bornAddress')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
									}
									value={bornAdress}
									onChange={(v) => setBornAdress(v.target.value)}
								/>
							</div>
							<div className="flex flex-col gap-2 items-start w-full">
								<p>{t('bornDate')}</p>
								<input
									type="text"
									placeholder={t('bornDate')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									value={ageData}
									onChange={(v) => setAgeData(v.target.value)}
								/>
							</div>
						</div>
						<div className="flex flex-col sm:flex-row items-center gap-2">
							<div className="flex flex-col gap-2 items-start w-full">
								<p>{t('workplace')}</p>
								<input
									type="text"
									placeholder={t('workplace')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={workplace}
									onChange={(v) => setWorkplace(v.target.value)}
								/>
							</div>
							<div className="flex flex-col gap-2 items-start w-full">
								<p>{t('salary')}</p>
								<input
									type="text"
									placeholder={t('salary')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={salary}
									onChange={(v) => setSalary(v.target.value)}
								/>
							</div>
						</div>
						<div className="flex flex-col sm:flex-row items-center gap-2">
							<div className="flex flex-col gap-2 items-start w-full">
								<p>{t('status')}</p>
								<select
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									value={score}
									onChange={(v) => setScore(v.target.value)}
								>
									<option value={''}></option>
									{userFields
										?.find((item) => item.Name === 'Score')
										?.ValidValuesMD.map((value) => (
											<option value={value.Value}>
												{' '}
												{value.Value} - {value.Description}
											</option>
										))}
								</select>
							</div>

							<div className="flex flex-col gap-2 items-start w-full">
								<p>Насия</p>
								<select
									placeholder="Насия"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									value={nasiya}
									onChange={(v) => setNasiya(v.target.value)}
								>
									<option value={''}></option>
									{userFields
										?.find((item) => item.Name === 'Nasiya')
										?.ValidValuesMD.map((value) => (
											<option value={value.Value}>{value.Description}</option>
										))}
								</select>
							</div>
						</div>
						<div className="flex flex-col sm:flex-row items-center gap-2">
							<div className="flex flex-col gap-2 items-start w-full">
								<p>KATM</p>
								<select
									placeholder="Katm"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={katm}
									onChange={(v) => setKatm(v.target.value)}
								>
									<option value={''}></option>
									{userFields
										?.find((item) => item.Name === 'KATM')
										?.ValidValuesMD.map((value) => (
											<option value={value.Value}>{value.Description}</option>
										))}
								</select>
							</div>
							<div className="flex flex-col gap-2 items-start w-full">
								<p>MIB</p>
								<select
									placeholder="U_MIB"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={mib}
									onChange={(v) => setMib(v.target.value)}
								>
									<option value={''}></option>
									{userFields
										?.find((item) => item.Name === 'MIB')
										?.ValidValuesMD.map((value) => (
											<option value={value.Value}>{value.Description}</option>
										))}
								</select>
							</div>
						</div>
						<div className="flex flex-col sm:flex-row items-center gap-2">
							<div className="flex flex-col gap-2 items-start w-full">
								<p>{t('smsSendLan')}</p>
								<select
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									value={language}
									onChange={(e) => setLanguage(e.target.value)}
								>
									<option value="">{''}</option>

									{userFields
										?.find((item) => item.Name === 'Language')
										?.ValidValuesMD.map((value) => (
											<option value={value.Value}>{value.Description}</option>
										))}
								</select>
							</div>
							<div className="flex flex-col gap-2 items-start w-full">
								<p>{t('comment')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={comment}
									onChange={(v) => setComment(v.target.value)}
								/>
							</div>
						</div>
						<div className="flex  flex-col items-start mt-5 gap-2 w-full ">
							<p>{t('comment')}</p>
							<textarea
								placeholder={`${t('comment')}`}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
								}
								value={comment2}
								onChange={(v) => setComment2(v.target.value)}
							/>
						</div>
						<div className="flex flex-col items-start gap-2 mt-5 w-full ">
							<p>{t('address2')}</p>
							<textarea
								placeholder={`${t('address2')}`}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
								}
								disabled={true}
								value={address2}
							/>
						</div>
					</div>
					<div className="mt-10">
						{path.map((image, index) =>
							image.has ? (
								image.path && (
									<div
										key={index}
										className="flex w-full items-start flex-col gap-3 mt-5 mb-10"
									>
										<div>
											<p className="font-bold">
												{t('photo')}-{index + 1}
											</p>
										</div>
										<div className="flex flex-col sm:flex-row items-center gap-3">
											<AntButton
												onClick={() => viewImage(index)}
												className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
											>
												{t('download')}
											</AntButton>
											<AntButton
												onClick={() => deleteImage(index)}
												className="h-10 w-full sm:w-[150px] rounded-lg p-2 bg-red-500 text-white"
											>
												{t('delete')}
											</AntButton>
											{index === path.length - 1 && (
												<AntButton
													onClick={addNewRow}
													className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
												>
													{t('add2')}
												</AntButton>
											)}
										</div>
									</div>
								)
							) : (
								<div
									key={index}
									className="flex w-full flex-col items-start gap-3 mt-5 mb-10"
								>
									<div>
										<p className="font-bold">
											{t('photo')}-{index + 1}
										</p>
									</div>
									<div className="flex flex-col sm:flex-row items-center gap-3">
										<Input
											type="file"
											onChange={(e) => handleFileChange(e, index)}
											className="w-full"
										/>
										<AntButton
											onClick={() => uploadImg(index)}
											loading={image.imgLoading}
											className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
										>
											{t('upload')}
										</AntButton>
										{image.path && (
											<>
												<AntButton
													onClick={() => viewImage(index)}
													className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
												>
													{t('download')}
												</AntButton>
												<AntButton
													onClick={() => deleteImage(index)}
													className="h-10 w-full sm:w-[150px] rounded-lg p-2 bg-red-500 text-white"
												>
													{t('delete')}
												</AntButton>
											</>
										)}
										{index !== 0 && (
											<AntButton
												onClick={() => deleteRow(index)}
												className="h-10 w-full sm:w-[100px] rounded-lg p-2 bg-red-500 text-white"
											>
												{t('cancel')}
											</AntButton>
										)}
										{index === path.length - 1 && (
											<AntButton
												onClick={addNewRow}
												className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
											>
												{t('add2')}
											</AntButton>
										)}
									</div>
								</div>
							),
						)}
					</div>

					<div className="flex gap-4 justify-center my-4">
						<div className="flex gap-1">
							<input
								type="radio"
								id="male"
								name="fav_language"
								checked={male === 'Male'}
								onChange={(v) => {
									setMale(v.target.value)
								}}
								value="Male"
							/>

							<label htmlFor="male">{t('male')}</label>
						</div>
						<div className="flex gap-1">
							<input
								type="radio"
								id="female"
								name="fav_language"
								checked={male === 'Female'}
								onChange={(v) => {
									setMale(v.target.value)
								}}
								value="Female"
							/>
							<label htmlFor="female">{t('female')}</label>
						</div>
					</div>

					<div className="flex flex-col sm:flex-row items-center justify-between gap-3">
						<AntButton
							className="h-10 w-full sm:w-[100px] bg-red-500 text-white py-2 px-4 rounded-lg "
							onClick={() => {
								onClose()
								setIsOpenModal(false)
							}}
						>
							{t('no')}
						</AntButton>
						<AntButton
							loading={isLoading}
							className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
							onClick={submit}
						>
							{t('yes')}
						</AntButton>
					</div>
				</div>
			</CreateUserStyle>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
		</Modal>
	)
}

export default memo(UpdateUserModal)
